/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined, CreditCardOutlined, StarOutlined } from '@ant-design/icons';
import Image from 'next/image';

import CustomButton from '@source/components/CustomButton/CustomButton';
import UserIcon from '@source/components/TopNavigation/assets/UserIcon';
import PersonalIcon from '@source/components/TopNavigation/assets/PersonalIcon';
import ViewingHistoryIcon from '@source/components/TopNavigation/assets/ViewingHistoryIcon';
import { IProfileDefaultResponse } from '@source/interface/userProfile';
import useUserProfileServices from '@source/hooks/useUserProfileServices';
import { URL_CONFIG } from '@source/constants/urlConfig';
import useRegionConfig from '@source/hooks/useRegionConfig';
import { getStaticCDN } from '@design-system/utils/utils';
import { ENV_URL } from '@source/constants/common';
import { NEXT_PUBLIC_WEB_CR } from '@source/constants/env';

const auctionsIcon = getStaticCDN('/assets/shared/user-profile/auctions-icon.svg');

const StyledAuthSmallItem = styled.div`
  width: 100%;
  .header {
    padding: 10px 20px;
    background-color: ${(props) => props.theme.color.primaryBase};
    font-size: 14px;
    color: ${(props) => props.theme.color.background};
    display: flex;
    align-items: center;

    a {
      color: ${(props) => props.theme.color.background};
    }

    .header_link {
      display: inline-flex;
      align-items: center;
    }

    .user-icon {
      fill: ${(props) => props.theme.color.background};
      margin-right: 8px;
    }
  }
  .content {
    padding: 0px 8px 14px 8px;

    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 13px 12px;
      color: ${(props) => props.theme.color.onSurface};
      &:not(:first-child) {
        border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
      }
    }

    .action-container {
      border-top: 1px solid ${(props) => props.theme.color.onBackgroundLowEmphasis};
      padding-top: 14px;
    }
  }

  .personal-icon,
  .viewing-history-icon,
  .anticon {
    fill: ${(props) => props.theme.color.primaryBase};
    margin-right: 8px;
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const StyledLogOutButton = styled(CustomButton)`
  width: calc(100% - 24px);
  margin: 0 auto;
  ${(props) => props.theme.typo.familyGoogle.semiBold};
  font-weight: 600;
  border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
  color: ${(props) => props.theme.color.onSurface};

  &:hover,
  &:focus {
    border: ${(props) => `1px solid ${props.theme.color.onBackgroundLowEmphasis}`};
    box-shadow: none;
    color: ${(props) => props.theme.color.onSurface};
  }
`;

const StyledIcon = styled.div`
  margin-right: 8px;
`;

const AuthSmallItem = ({ profile }: { profile: IProfileDefaultResponse | null }) => {
  const { t, i18n } = useTranslation();
  // const { pushGTMEvent } = useGTMEvent();
  const { logOut, hideLoginModal } = useUserProfileServices();

  const {
    countryConfig: { country },
  } = useRegionConfig();

  const handleLogOut = () => {
    logOut({ country: country || 'sg' });

    hideLoginModal();
  };

  return (
    <StyledAuthSmallItem>
      <div className="header">
        <a
          className="header_link"
          href={URL_CONFIG({ region: country || 'sg', lang: i18n.language, path: 'profile' }).formatted}
        >
          <UserIcon width={16} height={16} />
          {profile?.name}
        </a>
      </div>
      <div className="content">
        <a
          className="content-item"
          href={URL_CONFIG({ region: country || 'sg', lang: i18n.language, path: 'profile/edit' }).formatted}
        >
          <PersonalIcon width={16} height={16} />
          {t('home.topNavigation.account.personalInfo')}
        </a>

        {country === 'my' && (
          <a
            className="content-item"
            href={URL_CONFIG({ region: country || 'sg', lang: i18n.language, path: 'profile/appointments' }).formatted}
          >
            <CalendarOutlined width={16} height={16} />
            {t('home.topNavigation.account.appointments')}
          </a>
        )}

        <a
          className="content-item"
          href={URL_CONFIG({ region: country || 'sg', lang: i18n.language, path: 'profile/viewing-history' }).formatted}
        >
          <ViewingHistoryIcon width={16} height={16} />
          {t('home.topNavigation.account.viewingHistory')}
        </a>

        {country === 'my' && (
          <a className="content-item" href={`${NEXT_PUBLIC_WEB_CR}/my/en/seller/home`}>
            <StyledIcon>
              <Image className="auctions-icon" src={auctionsIcon} width={16} height={16} />
            </StyledIcon>
            {t('home.topNavigation.account.auctions')}
          </a>
        )}

        {country === 'my' && (
          <a
            className="content-item"
            href={URL_CONFIG({ region: country || 'sg', lang: i18n.language, path: 'profile/offers' }).formatted}
          >
            <StarOutlined width={20} height={20} style={{ fontSize: '20px' }} />
            Offers
            {/* {t('home.topNavigation.account.appointments')} */}
          </a>
        )}

        {country === 'my' && (
          <a
            className="content-item"
            href={URL_CONFIG({ region: country || 'sg', lang: i18n.language, path: 'profile/payments' }).formatted}
          >
            <CreditCardOutlined width={20} height={20} style={{ fontSize: '20px' }} />
            {t('home.topNavigation.account.payments')}
          </a>
        )}

        <div className="action-container">
          <StyledLogOutButton onClick={handleLogOut} variant="outline">
            {t('home.topNavigation.account.logOut')}
          </StyledLogOutButton>
        </div>
      </div>
    </StyledAuthSmallItem>
  );
};

export default AuthSmallItem;
