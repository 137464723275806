import { TitleValueProps } from '@source/types';
import {
  formatCurrency,
  getFormatCurrencyValue,
  formatAndRound,
  getFormatCurrencyValueID,
} from '@source/pages/CarDetail/utils';
import { LoanCalculatorProps } from '@source/pages/CarDetail/components/DetailLoanCalculator/configs/loanCalculatorProps';
import { ID_DONW_PAYMENT_RATE_BREAK_POINT } from '@design-system/components/BrowsingCard/constants';

export const formatCommaID = (value: string) => {
  const formatValue = value?.replace(/,/g, '.');

  return formatValue;
};

export const CONTENT_ID = {
  title: 'details.financing.title',
  subTitle: 'details.financing.subTitle',
  desc: 'details.financing.desc',
  estMonthlyInstalmentText: 'details.financing.estMonthlyInstalment',
  noteOne: 'details.financing.note1',
  noteTwo: 'details.financing.note2',
  disclaimer: 'details.financing.disclaimer',
  disclaimer2: 'details.financing.disclaimer2',
  tabs: [],
};

export const DP_15_INTEREST_RATE: Record<number, number> = {
  12: 8.39,
  24: 9.02,
  36: 10.25,
  48: 10.92,
  60: 11.59,
};

export const MIN_DOWN_PAYMENT_RATE = 15;
export const DOWN_PAYMENT_RATE_BREAK_POINT = 30;

export const DEFAULT_LOAN_TENURE = 36;
export const DEFAULT_DOWNPAYMENT_RATE = 30;

export const DP_SPECIAL_INTEREST_RATE: Record<number, number> = {
  12: 7.4,
  24: 8.2,
  36: 8.8,
  48: 9.4,
  60: 10.0,
};

export const DP_SPECIAL_INTEREST_RATE_FOR_DOWNPAYMENT_RATE_LESS_20: Record<number, number> = {
  12: 9.81,
  24: 10.87,
  36: 11.96,
  48: 12.74,
  60: 13.52,
};

export const DP_SPECIAL_INTEREST_RATE_FOR_DOWNPAYMENT_RATE_GREATER_20: Record<number, number> = {
  12: 8.15,
  24: 9.02,
  36: 9.69,
  48: 10.32,
  60: 10.96,
};

export const RULES_INSURANCE_AMOUNT = [
  { rate: 100, month: 12 },
  { rate: 85, month: 24 },
  { rate: 75, month: 36 },
  { rate: 70, month: 48 },
  { rate: 65, month: 60 },
];

export const INSURANCE_RATE = 0.65;

export const ADMIN_FEE = 6000000;
export const PROVISI_FEE = 1.5;

export const LOAN_TENURE_ID = [
  { title: 'details.financing.countYear', value: 12, paramObj: { count: 1 } },
  { title: 'details.financing.countYear', value: 24, param: 2, paramObj: { count: 2 } },
  { title: 'details.financing.countYear', value: 36, param: 3, paramObj: { count: 3 } },
  { title: 'details.financing.countYear', value: 48, param: 4, paramObj: { count: 4 } },
  { title: 'details.financing.countYear', value: 60, param: 5, paramObj: { count: 5 } },
];

const getInterestRateByDownpaymentRate = (downpaymentRate: number): Record<number, number> =>
  downpaymentRate < 20
    ? DP_SPECIAL_INTEREST_RATE_FOR_DOWNPAYMENT_RATE_LESS_20
    : DP_SPECIAL_INTEREST_RATE_FOR_DOWNPAYMENT_RATE_GREATER_20;

export const getInterestRatesOptionsID = (downpaymentRate: number): TitleValueProps[] => {
  const options: Record<any, number> = getInterestRateByDownpaymentRate(downpaymentRate);

  return Object.keys(options).map((key) => ({
    title: `${options[key]} %`,
    value: options[key],
  }));
};

const getDownpayment = (askingPrice: number, downPaymentRate: number) => (askingPrice * downPaymentRate) / 100;

const getDownpaymentRate = (askingPrice: number, downPayment: number) => (downPayment / askingPrice) * 100;

const getMinDownpayment = (askingPrice: number, minDownPaymentRate: number) => (askingPrice * minDownPaymentRate) / 100;

const getLoanAmount = (askingPrice: number, downPayment: number) => askingPrice - downPayment;

export const getInsuranceFee = ({ askingPrice, loanTenure }: { askingPrice: number; loanTenure: number }) => {
  const insuranceFeeOptions = RULES_INSURANCE_AMOUNT.filter((item) => item.month <= loanTenure);
  let val = 0;
  insuranceFeeOptions.forEach((item) => {
    val += (((item.rate * askingPrice) / 100) * INSURANCE_RATE) / 100;
  });
  return val;
};

export const getMonthlyInstallmentID = ({
  loanAmount,
  askingPrice,
  loanTenure,
  interestRate,
}: {
  loanAmount: number;
  askingPrice: number;
  loanTenure: number;
  interestRate: number;
}) => {
  const provisiFee = (loanAmount * PROVISI_FEE) / 100;

  const insuranceFee = getInsuranceFee({ askingPrice, loanTenure });

  const baselineLoan = loanAmount + provisiFee + insuranceFee;

  const interest = ((baselineLoan * interestRate) / 100) * (loanTenure / 12);

  return (interest + baselineLoan) / loanTenure;
};

export const getInitialLoanValuesID = (data: any): LoanCalculatorProps => {
  const { keyWords, promotionalPrice, askingPrice } = data;

  const isPromotion = keyWords && promotionalPrice;
  const price = isPromotion ? promotionalPrice : askingPrice;
  const carPrice = price || 0;

  const loanTenure = DEFAULT_LOAN_TENURE;

  const downPaymentRate = DEFAULT_DOWNPAYMENT_RATE;

  const interestRateOptions = getInterestRatesOptionsID(downPaymentRate);

  const downPayment = getDownpayment(carPrice, downPaymentRate);

  const loanAmount = getLoanAmount(carPrice, downPayment);

  const interestRate = getInterestRateByDownpaymentRate(downPaymentRate)[loanTenure];

  const monthlyInstallment = getMonthlyInstallmentID({
    loanAmount,
    askingPrice: carPrice,
    loanTenure,
    interestRate,
  });

  const totalDownPayment = downPayment + ADMIN_FEE + monthlyInstallment;

  return {
    askingPrice: formatCommaID(formatCurrency(parseInt(carPrice, 10))),
    loanAmount: formatCommaID(formatCurrency(loanAmount)),
    downPayment: formatCommaID(formatCurrency(downPayment)),
    totalDownPayment: formatCommaID(formatAndRound(totalDownPayment).toString()),
    interestRate,
    loanTenure,
    monthlyInstallment: formatCommaID(formatAndRound(monthlyInstallment).toString()),
    loanTenureOptions: LOAN_TENURE_ID,
    interestRateOptions,
  };
};

export const calculateLoanValuesID = (data: any, fieldChangeName: string): LoanCalculatorProps => {
  let {
    askingPrice,
    loanAmount,
    downPayment,
    interestRate,
    loanTenure,
    monthlyInstallment,
    interestRateOptions,
    totalDownPayment,
  } = data;
  askingPrice = getFormatCurrencyValueID(askingPrice);
  loanAmount = getFormatCurrencyValueID(loanAmount);
  downPayment = getFormatCurrencyValueID(downPayment);
  interestRate = getFormatCurrencyValue(interestRate);
  loanTenure = getFormatCurrencyValue(loanTenure);
  const minDownPayment = getMinDownpayment(askingPrice, MIN_DOWN_PAYMENT_RATE);

  switch (fieldChangeName) {
    case 'askingPrice':
      if (askingPrice < 0) {
        askingPrice = 0;
        downPayment = 0;
        loanAmount = 0;
      } else {
        downPayment = (askingPrice * ID_DONW_PAYMENT_RATE_BREAK_POINT) / 100; // 30% - https://carrotech.atlassian.net/browse/CXBF-403?focusedCommentId=34491
        if (downPayment < minDownPayment || downPayment > askingPrice) {
          downPayment = minDownPayment;
        }
        loanAmount = askingPrice - downPayment;
      }
      break;
    case 'loanAmount':
      if (loanAmount < 0 || loanAmount > askingPrice) {
        if (downPayment < minDownPayment) {
          downPayment = minDownPayment;
        }
        loanAmount = askingPrice - downPayment;
      }

      downPayment = askingPrice - loanAmount;
      break;
    case 'downPayment':
      if (downPayment < 0 || downPayment > askingPrice) {
        downPayment = minDownPayment;
      }

      loanAmount = askingPrice - downPayment;
      break;
    default:
      break;
  }

  const calculatedDownPaymentRate = getDownpaymentRate(askingPrice, downPayment);

  interestRateOptions = getInterestRatesOptionsID(calculatedDownPaymentRate);

  interestRate = getInterestRateByDownpaymentRate(calculatedDownPaymentRate)[loanTenure];

  monthlyInstallment =
    getMonthlyInstallmentID({
      loanAmount,
      askingPrice,
      loanTenure,
      interestRate,
    }) || 0;

  totalDownPayment = downPayment + ADMIN_FEE + monthlyInstallment;

  return {
    askingPrice: formatCommaID(formatCurrency(askingPrice)),
    loanAmount: formatCommaID(formatCurrency(loanAmount)),
    downPayment: formatCommaID(formatCurrency(downPayment)),
    totalDownPayment: formatCommaID(formatAndRound(totalDownPayment).toString()),
    interestRate,
    loanTenure,
    monthlyInstallment: formatCommaID(formatAndRound(monthlyInstallment).toString()),
    interestRateOptions,
  };
};
