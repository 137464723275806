import numeral from 'numeral';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { getFormattedPreciseRange } from '@source/utils/utils';
import {
  ID_CALCULATED_INTEREST_RATE,
  ID_DEFAULT_DOWNPAYMENT_RATE,
  ID_DEFAULT_LOAN_TENURE,
  ID_INSURANCE_RATE,
  ID_RULES_INSURANCE_AMOUNT,
} from './constants';

dayjs.extend(isSameOrBefore);

const fakeHandOverDate = '2024-08-05';

export const formatCarInfo = (car: any) => ({
  coeExpiryDate: car?.inventory?.coe_expiry_date || car?.coe_expiry_date,
  depreciation: car?.listing?.current_depreciation || car?.current_depreciation,
  id: car?.listing?.slug || car?.id, // use car?id as item id for th
  instalment: car?.listing?.installment || car?.installment,
  isCarroCertified: car?.listing?.is_ccpo || car?.flags?.is_ccpo || car?.listing?.flags?.is_ccpo /* ccpo in TH */,
  isCarroCertifiedLite: car?.listing?.is_ccpo_lite || car?.flags?.is_ccpo_lite || car?.listing?.flags?.is_ccpo_lite,
  mileage: car?.listing?.mileage || car?.inventory?.mileage || car?.mileage /* mileage in TH */,
  originalPrice: car?.original_price || car?.listing?.original_price,
  owner: car?.inventory?.number_of_owners || car?.number_of_owners,
  numberOfOwners:
    car?.inventory?.number_of_owners /* only use in HK since Algolia HK return number instead of string  */,
  photos:
    car?.listing?.photos ||
    (car?.photos && (car?.photos || []).map((item: any) => item?.full_path)) ||
    (car?.photo && car.photo.full_path ? [car.photo.full_path] : []) /* photos in TH */,
  price: car?.listing?.asking_price || car?.asking_price || car?.price /* price in TH */,
  releaseDate: car?.inventory?.original_registration_date || car?.original_registration_date,
  title: car?.listing?.title || car?.title /* title in TH */,
  transmission: car?.inventory?.transmission || car?.transmission,
  location: car?.location?.display_name,
  color: car?.inventory?.color || car?.color,
  base_color_key: car?.inventory?.base_color_key,
  color_variation_key: car?.inventory?.color_variation_key,
  fuelType: car?.inventory?.fuel_type || car?.fuel_type,
  wheelDrives: car?.inventory?.wheel_drives || car?.wheel_drives,
  flags: car?.listing?.flags || car?.flags /* flags in TH */,
  manufactureYear: car?.inventory?.year_of_manufacture || car?.inventory?.manufacture_year || car?.year_of_manufacture,
  isPendingSale: car?.listing?.is_pending_sale,
  isSold: car?.listing?.is_sold,
  isComingSoon: car?.listing?.is_coming_soon,
  region: car?.region?.display_name,
  promoLabel: car?.promo_label,
  askingPrice: car?.listing?.asking_price /* for TH */,
  askingPriceID: car?.listing?.asking_price /* for ID */,
  additionalData: car?.listing?.additional_data,
  promotionalPrice: car?.listing?.promotional_price,
  isNewArrival:
    car?.listing?.is_new_arrival ||
    car?.listing?.flags?.is_new_arrival ||
    car?.flags?.is_new_arrival ||
    car?.is_new_arrival,

  // flags for SG feature badge
  isCarroAnywhere: car?.listing?.is_carro_anywhere,
  isAgentWarranty: car?.listing?.is_agent_warranty,
  isAgentServiced: car?.listing?.is_agent_serviced,
  isValidAgentWarranty: car?.listing?.agent_warranty?.expired_date
    ? dayjs().isSameOrBefore(car.listing.agent_warranty.expired_date, 'D')
    : false,
  agentWarranty: car?.listing?.agent_warranty,
  newArrivalRibbon: car?.inventory?.estimated_handover_date
    ? dayjs(car.inventory.estimated_handover_date).startOf('day').diff(dayjs().startOf('day'), 'd')
    : undefined,

  // flags for TH feature badge
  isCarroOwned: car?.flags?.is_carro_owned,
  isFinance: car?.flags?.is_finance,
  isGoodDeal: car?.flags?.is_good_deal,

  // for SG priceInfoConent
  downpaymentPercentage: car?.listing?.downpayment_percentage,
  maxLoan: car?.listing?.maximum_loan_percentage,

  // flag for MY promotion
  isPromo: car?.is_promo,
  promotion: car?.promotion,

  twWheelDrives: car?._highlightResult?.inventory?.wheel_drives?.value || car?.wheel_drives,
  priceWithGstTH: car?.listing?.price_with_gst /* for TH */,
  gst: car?.listing?.gst,

  allInPrice: car?.listing?.additional_data?.all_in_price,
  insideBadges: car?.inside_badges,
});

export type CountryCode = 'sg' | 'my' | 'id' | 'th' | 'jp' | 'hk';

export const mappingCountryCurrency: Record<CountryCode, string> = {
  sg: '$',
  my: 'RM',
  id: 'Rp',
  th: '฿',
  jp: '¥',
  hk: '$',
};

/** TODO: Regular expression breaks in safari, will be checking back later
 * https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group)  */
/* export const numberThousandSeparatorFormat = (value: number, thousandSeparator?: string) => {
  if (value) {
    thousandSeparator = thousandSeparator || ',';
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, thousandSeparator);
  } else {
    return null;
  }
*/

export const formatAndRound = (val: number) => {
  const rounded = val - (val % 1000) + 1000;
  return `${numeral(rounded || 0).format('0,0')}`;
};

export const calculateVATPrice = (price: number, percent: number) => Math.round(price * percent) / 100;

export const calculateTHMonthlyInstallment = (askingPrice: any) => {
  // 0.05 = 5% inrerest rate
  // 7 = 7 years
  // 84 = 7 years --> 84 months
  // 0.07 = VAT 7%
  const installment = (askingPrice * 0.05 * 7 + askingPrice) / 84;
  const monthlyInstallment = Math.round(installment * 0.07 + installment);
  const formattedInstallment = monthlyInstallment ? numeral(monthlyInstallment).format('0,0') : '-';

  return formattedInstallment;
};

export const getIDdiscountPrice = (original: number, asking: number) => {
  if (original <= asking) {
    return '';
  }

  const actualDiscount = original - asking;
  const discountInJt = actualDiscount / 1000000;
  if (discountInJt < 1) {
    return `Rp${discountInJt.toFixed(3)}Jt`;
  }
  return `Rp${discountInJt}Jt`;
};

export const getCOEexpireDate = (date?: string, unitObj?: { [key: string]: string }, suffix = '') => {
  if (date) {
    const coeDiff = dayjs.preciseDiff(dayjs(date), dayjs(), true);
    const { years } = coeDiff;
    if (years > 0) {
      return `(${getFormattedPreciseRange(coeDiff, ['years', 'months'], unitObj)} ${suffix})`;
    }
    return `0${unitObj?.year}(${getFormattedPreciseRange(coeDiff, ['months'], unitObj)} ${suffix})`;
  }
  return '';
};

const getIDInsuranceFee = ({ askingPrice, loanTenure }: { askingPrice: number; loanTenure: number }) => {
  const insuranceFeeOptions = ID_RULES_INSURANCE_AMOUNT.filter((item: any) => item.month <= loanTenure);
  let val = 0;
  insuranceFeeOptions.forEach((item: any) => {
    val += (((item.rate * askingPrice) / 100) * ID_INSURANCE_RATE) / 100;
  });
  return val;
};

export const getIsAdjacentToActiveIndex = (currentIndex: number, activeIndex: number, total: number) => {
  if (currentIndex === activeIndex) {
    return true;
  }

  if (activeIndex === 0) {
    return currentIndex === total - 1 || currentIndex === 1;
  }

  if (activeIndex === total - 1) {
    return currentIndex === activeIndex - 1 || currentIndex === 0;
  }

  return currentIndex === activeIndex - 1 || currentIndex === activeIndex + 1;
};

export const calculateIDMonthlyInstallment = ({
  askingPriceID,
  additionalData,
  promotionalPrice,
}: {
  askingPriceID: any;
  additionalData: any;
  promotionalPrice: any;
}) => {
  const ID_PROVISI_FEE = 1.5;
  const { key_words: keyWords } = additionalData || {};

  const isPromotion = keyWords && promotionalPrice;
  const price = isPromotion ? promotionalPrice : askingPriceID;
  const carPrice = price || 0;

  const askingPrice = parseInt(carPrice, 10);

  const loanTenure = ID_DEFAULT_LOAN_TENURE;

  const downPaymentRate = ID_DEFAULT_DOWNPAYMENT_RATE;

  const downPayment = (askingPrice * downPaymentRate) / 100;

  const loanAmount = askingPrice - downPayment;

  const provisiFee = (loanAmount * ID_PROVISI_FEE) / 100;

  const insuranceFee = getIDInsuranceFee({ askingPrice, loanTenure });

  const baselineLoan = loanAmount + provisiFee + insuranceFee;

  const interest = ((baselineLoan * ID_CALCULATED_INTEREST_RATE) / 100) * (loanTenure / 12);

  const monthlyInstallment = (interest + baselineLoan) / loanTenure;

  return formatAndRound(monthlyInstallment);
};

const JPE: Record<number, { key: string; name: string }> = {
  1989: { key: 'H', name: 'Heisei' },
  2019: { key: 'R', name: 'Reiwa' },
};

export const getJapaneseYearFormat = (gregorianYear: number) => {
  const applicableYear = Object.keys(JPE)
    .map(Number)
    .findLast((year) => gregorianYear >= year);

  if (!applicableYear) {
    return gregorianYear;
  }

  const era = JPE[applicableYear];
  const eraYear = gregorianYear - applicableYear + 1;

  return `${gregorianYear} (${era.key}${eraYear})`;
};
