import useWindowResize from '@source/hooks/useWindowResize';
import { TRegionKey } from '@source/interface';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ANNOUNCEMENT_CONTENT from './constants';
import CustomTextWithFontFamily from '../CustomTextWithFontFamily';

interface TemporaryBannerProps {
  countryCode: TRegionKey | undefined;
  isStickTemporaryBanner?: boolean;
  unStickTemporaryBanner?: () => void;
  isTemporaryBannerToTop?: boolean;
  isSecondaryHeaderMobile?: boolean;
}

const StyledWrapper = styled.div<{
  isSticky?: boolean;
  zIndex: number;
  stickyTopPosition: number;
}>`
  ${(props) =>
    props.isSticky &&
    css`
      position: sticky;
      top: ${`${props.stickyTopPosition}px`};
      z-index: ${`${props.zIndex}`};
    `}
`;

const StyledContentWrapper = styled.a`
  display: flex;
  height: 35px;
  justify-content: center;
  align-items: center;
  background: #ff4c00;
  border-radius: 0px 0px 11px 11px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  gap: 8px;
  ${(props) => props.theme.typo.familyGoogle.semiBold}
  cursor: pointer;

  :hover {
    color: #fff;
  }

  .learn-more {
    font-weight: 400;
  }

  @media only screen and (max-width: 1279px) {
    font-size: 19px;
  }

  @media only screen and (max-width: 991px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 17px;
  }

  @media only screen and (max-width: 575px) {
    font-size: 16px;
  }
`;

const TemporaryBanner: FC<TemporaryBannerProps> = ({
  countryCode,
  isStickTemporaryBanner = false,
  unStickTemporaryBanner,
  isTemporaryBannerToTop,
  isSecondaryHeaderMobile,
}) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isScrollToFooter, setIsScrollToFooter] = useState(false);
  const [isOverTimeout, setIsOverTimeout] = useState<boolean>(false);
  const [stickyTopPosition, setStickyTopPosition] = useState<number>(0);
  const [stickyZIndex, setStickyZIndex] = useState<number>(999);
  const [enableSticky, setEnableSticky] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { href, mainText, subText, isShowTemporaryBanner } = ANNOUNCEMENT_CONTENT[countryCode] || {};

  useEffect(() => {
    const handleScroll = () => {
      const mainFooter = document.querySelector('#main-footer');
      if (mainFooter) {
        const { top } = mainFooter.getBoundingClientRect();
        setIsScrollToFooter(top <= 85); // 85 = 50 topNav + 35 temporaryBanner
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const changeStickTemporaryBanner = () => {
    setIsSticky(false);
    setIsOverTimeout(true);
    if (unStickTemporaryBanner) unStickTemporaryBanner();
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setIsSticky(isStickTemporaryBanner);
    if (isShowTemporaryBanner && isStickTemporaryBanner && !isOverTimeout) {
      setIsSticky(isStickTemporaryBanner);
      timeoutRef.current = setTimeout(() => {
        changeStickTemporaryBanner();
      }, 300000); // 5 minutes in milliseconds

      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }
  }, [isStickTemporaryBanner, countryCode]);

  const [width] = useWindowResize() as number[];
  const isMobileView = width && width < 1280;

  const { t } = useTranslation();

  useEffect(() => {
    if (isMobileView) {
      if (isTemporaryBannerToTop) {
        setStickyTopPosition(isScrollToFooter ? 50 : 0);
      } else {
        setStickyTopPosition(50);
      }
    } else {
      setStickyTopPosition(0);
    }

    if (isShowTemporaryBanner) {
      setStickyZIndex(isOverTimeout ? 990 : 1000);
    } else {
      setStickyZIndex(990);
    }
  }, [isMobileView, isTemporaryBannerToTop, isScrollToFooter, countryCode, isOverTimeout]);

  useEffect(() => {
    if (isMobileView) {
      setEnableSticky(true);
    } else if (isShowTemporaryBanner) {
      if (isOverTimeout && !isMobileView) {
        setEnableSticky(false);
      } else {
        setEnableSticky(isSticky);
      }
    }
  }, [countryCode, isOverTimeout, isMobileView, isSticky]);

  if (isMobileView && isSecondaryHeaderMobile) return null;

  return isShowTemporaryBanner ? (
    <StyledWrapper isSticky={enableSticky} zIndex={stickyZIndex} stickyTopPosition={stickyTopPosition}>
      <StyledContentWrapper href={href} target="_blank">
        <span>
          <CustomTextWithFontFamily text={t(mainText)} />
        </span>
        <span className="learn-more">{t(subText)}</span>
      </StyledContentWrapper>
    </StyledWrapper>
  ) : null;
};

export default TemporaryBanner;
