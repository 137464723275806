import React from 'react';
import { Affix } from 'antd';
import styled from 'styled-components';

import useRegionConfig from '@source/hooks/useRegionConfig';
import WhatsappButton from '@source/components/SocialMessenger/WhatsappButton';
import LineButton from '@source/components/SocialMessenger/LineButton';
import { TRegionKey } from '@source/interface';
import { WHATSAPP_NUMBER } from '@source/constants/common';

interface SocialMessengerContainerProps {
  offsetBottom?: number;
}

const StyledChatZone = styled.div`
  height: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StyledButton = styled.div<{ isCountryUsingWhatsapp: boolean }>`
  background-color: ${(props) => props.theme.color.socialMediaBackground};
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 15px;
  ${(props) => props.theme.typo.familyGoogle.bold};
  font-weight: normal;
  box-shadow: ${(props) => props.theme.color.boxShadow.socialMedia};
  max-width: 300px;
  margin-right: 30px;
  z-index: 999;

  .content-element {
    color: ${(props) => props.theme.color.onPrimary};
    margin-left: 10px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 991px) {
    padding: 8px;
    border-radius: 100%;
    box-shadow: unset !important;
    font-size: 11px;
    line-height: 1;
    display: flex;
    width: 56px;
    height: 56px;
    min-width: auto;
    min-height: auto;
    justify-content: center;
    align-items: center;

    a {
      width: ${(props) => (props.isCountryUsingWhatsapp ? '24px' : '39px')};
      height: ${(props) => (props.isCountryUsingWhatsapp ? '24px' : '39px')};
    }

    img {
      width: ${(props) => (props.isCountryUsingWhatsapp ? '24px' : '39px')} !important;
    }

    .content-element {
      display: none;
    }
  }
`;

/**
 *  Social media messenger (Whatsapp/Line)
 *  SG, MY, ID - Whatsapp
 *  TH, TW - Line
 *
 *  Affix refer to https://ant.design/components/affix/
 */
const SocialMessengerContainer: React.FC<SocialMessengerContainerProps> = ({ offsetBottom }) => {
  const {
    countryConfig: { country: countryCode },
  } = useRegionConfig();
  const whatsappNumber: string = WHATSAPP_NUMBER?.[countryCode as TRegionKey] || WHATSAPP_NUMBER.sg;

  const isCountryUsingWhatsapp = ['sg', 'my', 'id', 'hk'].includes(countryCode as string);
  return countryCode ? (
    <Affix offsetBottom={offsetBottom}>
      <StyledChatZone>
        <StyledButton isCountryUsingWhatsapp={isCountryUsingWhatsapp}>
          {isCountryUsingWhatsapp ? (
            <WhatsappButton countryCode={countryCode as TRegionKey} whatsappNumber={whatsappNumber} />
          ) : (
            <LineButton countryCode={countryCode as TRegionKey} />
          )}
        </StyledButton>
      </StyledChatZone>
    </Affix>
  ) : null;
};

SocialMessengerContainer.defaultProps = {
  offsetBottom: 18,
};

export default SocialMessengerContainer;
