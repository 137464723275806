import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { getStaticCDN } from '@design-system/utils/utils';

interface IStatusRibbon {
  flags?: {
    sold: boolean;
    pendingSale: boolean;
    comingSoon: boolean;
    newArrivalRibbon: number | undefined;
  };
  hasPromo?: boolean;
}

// TODO: Teh pls remove hardcode color and background color

const StyledStatusRibbonWrapper = styled.div<{ hasPromo: boolean }>`
  .coming-soon-ribbon {
    background-color: ${(props) => props.theme.color.primarySubtle};
  }

  .sale-ribbon {
    background-color: #212121;
  }

  .ribbon {
    top: 25px;
  }
`;

const StyledSoldOverlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  z-index: 3;
  -webkit-transform: translate3d(0, 0, 0);
`;

const StyledStatusRibbon = styled.div<{ opacity: string }>`
  position: absolute;
  top: 25px;
  z-index: 2;
  -webkit-transform: translate3d(0, 0, 0);
  border-radius: 0px 8px 8px 0px;
  opacity: ${(props) => props.opacity};
  display: flex;
  justify-content: space-evenly;
  height: 23px;

  .status {
    padding: 3px 6px;
    color: #fff;
    font-size: 12px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  .coming-soon {
    color: ${(props) => props.theme.color.primaryBase};
  }
`;

const StyledStatusIcon = styled.span`
  font-size: 14px;
  text-align: center;
  color: white;
  margin-left: 3px;
  margin-top: 3px;
  position: relative;
  height: 16px;
  width: 16px;
`;

const lockIcon = getStaticCDN('/static/img/icons/lock.svg');
const alertIcon = getStaticCDN('/static/img/icons/orange_alert.svg');

const StatusRibbon: React.FC<IStatusRibbon> = ({ flags, hasPromo }) => {
  const { t } = useTranslation();

  const { sold, pendingSale, comingSoon, newArrivalRibbon } = flags || {};

  const newArrivalRibbonContent =
    newArrivalRibbon && newArrivalRibbon > 1
      ? t('home.listingCard.arrivalInDays', { days: newArrivalRibbon })
      : t('home.listingCard.viewTomorrow');

  const renderPendingSaleStatus = (
    <StyledStatusRibbon className="ribbon sale-ribbon" opacity="0.6">
      <StyledStatusIcon>
        <Image layout="fill" src={lockIcon} />
      </StyledStatusIcon>
      <p className="status">{t('home.listingCard.pendingSale')}</p>
    </StyledStatusRibbon>
  );

  const renderSoldStatus = (
    <StyledSoldOverlay>
      <StyledStatusRibbon className="ribbon sale-ribbon" opacity="0.6">
        <StyledStatusIcon>
          <Image layout="fill" src={lockIcon} />
        </StyledStatusIcon>
        <p className="status">{t('home.listingCard.sold')}</p>
      </StyledStatusRibbon>
    </StyledSoldOverlay>
  );

  const renderComingSoonStatus = (
    <StyledStatusRibbon className="ribbon coming-soon-ribbon" opacity="1.0">
      <StyledStatusIcon>
        <Image layout="fill" src={alertIcon} />
      </StyledStatusIcon>
      <p className="status coming-soon">{t('home.listingCard.comingSoon')}</p>
    </StyledStatusRibbon>
  );

  const renderNewArrivalStatus = (
    <StyledStatusRibbon className="ribbon coming-soon-ribbon" opacity="1.0">
      <StyledStatusIcon>
        <Image layout="fill" src={alertIcon} />
      </StyledStatusIcon>
      <p className="status coming-soon">{newArrivalRibbonContent}</p>
    </StyledStatusRibbon>
  );

  const renderStatusRibbon = () => {
    if (sold) {
      return renderSoldStatus;
    }
    if (!sold && pendingSale) {
      return renderPendingSaleStatus;
    }
    if (!sold && !pendingSale && comingSoon) {
      return renderComingSoonStatus;
    }
    if (!sold && !pendingSale && !comingSoon && newArrivalRibbon) {
      return renderNewArrivalStatus;
    }
    return null;
  };

  return <StyledStatusRibbonWrapper hasPromo={!!hasPromo}>{renderStatusRibbon()}</StyledStatusRibbonWrapper>;
};

StatusRibbon.defaultProps = {
  flags: {
    sold: false,
    pendingSale: false,
    comingSoon: false,
    newArrivalRibbon: undefined,
  },
  hasPromo: false,
};

export default StatusRibbon;
