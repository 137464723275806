/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import Offer from '@source/services/Offer';
import {
  ICreatePaymentGateway,
  IGetLoanResultProps,
  IGetOfferPaymentSummary,
  IGetOfferProps,
  ILoanResultItem,
  IOfferDocumentsResponse,
  IOfferItem,
  IOfferListUploadOptions,
  IOfferVSO,
  IPaymentIntentProps,
  IRemoveSelectedFileTC,
  ISendAcceptTC,
  ISendFinanceOptionTC,
  ISendFinishUploadCash,
  ISendOfferTC,
  ISendUploadDoc,
} from '@source/interface/offer';
import { ICountryProps, IErrorResponse, IRejectedValue, ISuccessResponse } from '@source/interface/apiResponse';

const sliceName = 'OFFER';

// Todo: Init first
interface IOfferStateProps {
  currentOffer: IOfferItem | null;
  offers: IOfferItem[] | null;
  loanResult: any | null;
  total: number | undefined;
  workflow: any | null;
  vsoData: any | null;
  paymentSummary: any | null;
  currentDocuments: any | null;
  uploadOptions: IOfferListUploadOptions[] | null;
  offerBufferTime: number;
  loading: boolean | null;
  isRequiredFilesUploaded: boolean;
  errors: string | null;
  paymentIntentData: null;
  fpx: null;
  isAcceptedTC: boolean;
}

const INITIAL_STATE: IOfferStateProps = {
  currentOffer: null,
  offers: null,
  loanResult: null,
  total: 0,
  workflow: null,
  vsoData: null,
  paymentSummary: null,
  currentDocuments: null,
  uploadOptions: null,
  offerBufferTime: 0,
  isRequiredFilesUploaded: false,
  loading: null,
  errors: null,
  paymentIntentData: null,
  fpx: null,
  isAcceptedTC: false,
};

export const listOffer = createAsyncThunk<ISuccessResponse<IOfferItem[]>, ICountryProps, IRejectedValue>(
  `${sliceName}/listOffer`,
  async ({ country }: ICountryProps, { rejectWithValue }) => {
    try {
      const res = await Offer.listOffer(country);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getListUploadOptions = createAsyncThunk<
  ISuccessResponse<IOfferListUploadOptions[]>,
  ICountryProps,
  IRejectedValue
>(`${sliceName}/getListUploadOptions`, async ({ country }: ICountryProps, { rejectWithValue }) => {
  try {
    const res = await Offer.getListUploadOptions(country);
    return res.data;
  } catch (err: any) {
    // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    const error: AxiosError<IErrorResponse> = err; // cast the error for access
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});

export const getUploadedDocmuents = createAsyncThunk<
  ISuccessResponse<IOfferDocumentsResponse>,
  IGetOfferProps,
  IRejectedValue
>(`${sliceName}/getUploadedDocmuents`, async ({ country, offerId }: IGetOfferProps, { rejectWithValue }) => {
  try {
    const res = await Offer.getUploadedDocmuents(country, offerId);
    return res.data;
  } catch (err: any) {
    // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    const error: AxiosError<IErrorResponse> = err; // cast the error for access
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});

export const getOffer = createAsyncThunk<ISuccessResponse<IOfferItem>, IGetOfferProps, IRejectedValue>(
  `${sliceName}/getOffer`,
  async ({ country, offerId }: IGetOfferProps, { rejectWithValue }) => {
    try {
      const res = await Offer.getOffer(country, offerId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getOfferWorkFlow = createAsyncThunk<ISuccessResponse<IOfferItem>, IGetOfferProps, IRejectedValue>(
  `${sliceName}/getOfferWorkFlow`,
  async ({ country, offerId }: IGetOfferProps, { rejectWithValue }) => {
    try {
      const res = await Offer.getOfferWorkFlow(country, offerId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getOfferVSO = createAsyncThunk<ISuccessResponse<IOfferVSO>, IGetOfferProps, IRejectedValue>(
  `${sliceName}/getOfferVSO`,
  async ({ country, offerId }: IGetOfferProps, { rejectWithValue }) => {
    try {
      const res = await Offer.getOfferVSO(country, offerId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getOfferPaymentSummary = createAsyncThunk<
  ISuccessResponse<IOfferItem>,
  IGetOfferPaymentSummary,
  IRejectedValue
>(`${sliceName}/getOfferPaymentSummary`, async ({ country, offerId }: IGetOfferPaymentSummary, { rejectWithValue }) => {
  try {
    const res = await Offer.getOfferPaymentSummary(country, offerId);
    return res.data;
  } catch (err: any) {
    // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    const error: AxiosError<IErrorResponse> = err; // cast the error for access
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});

export const getLoanResult = createAsyncThunk<ISuccessResponse<ILoanResultItem>, IGetLoanResultProps, IRejectedValue>(
  `${sliceName}/getLoanResult`,
  async ({ country, offerId }: IGetLoanResultProps, { rejectWithValue }) => {
    try {
      const res = await Offer.getLoanResult(country, offerId);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPaymentIntent = createAsyncThunk(
  `${sliceName}/createPaymentIntent`,
  async ({ slug, data }: IPaymentIntentProps, { rejectWithValue }) => {
    try {
      const res = await Offer.createPaymentIntent({ slug, data });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendAcceptOffer = createAsyncThunk(
  `${sliceName}/sendAcceptOffer`,
  async ({ slug }: ISendOfferTC, { rejectWithValue }) => {
    try {
      const res = await Offer.sendAcceptOffer({ slug });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendAcceptTC = createAsyncThunk(
  `${sliceName}/sendAcceptTC`,
  async ({ slug, payload }: ISendAcceptTC, { rejectWithValue }) => {
    try {
      const res = await Offer.sendAcceptTC({ slug, payload });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendFinishUploadCash = createAsyncThunk(
  `${sliceName}/sendFinishUploadCash`,
  async ({ slug }: ISendFinishUploadCash, { rejectWithValue }) => {
    try {
      const res = await Offer.sendFinishUploadCash({ slug });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const removeSelectedFile = createAsyncThunk(
  `${sliceName}/removeSelectedFile`,
  async ({ slug, fileId }: IRemoveSelectedFileTC, { rejectWithValue }) => {
    try {
      const res = await Offer.removeSelectedFile({ slug, fileId });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendUploadDoc = createAsyncThunk(
  `${sliceName}/sendUploadDoc`,
  async ({ offerId, payload }: ISendUploadDoc, { rejectWithValue }) => {
    try {
      const res = await Offer.sendUploadDoc({ offerId, payload });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendFinanceOption = createAsyncThunk(
  `${sliceName}/sendFinanceOption`,
  async ({ slug, payload }: ISendFinanceOptionTC, { rejectWithValue }) => {
    try {
      const res = await Offer.sendFinanceOption({ slug, payload });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPaymentGateway = createAsyncThunk(
  `${sliceName}/createPaymentGateway`,
  async ({ slug, payload }: ICreatePaymentGateway, { rejectWithValue }) => {
    try {
      const res = await Offer.createPaymentGateway({ slug, payload });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchFPXPaymentIntent = createAsyncThunk(
  `${sliceName}/fetchFPXPaymentIntent`,
  async ({ slug, data }: IPaymentIntentProps, { rejectWithValue }) => {
    try {
      const res = await Offer.fetchFPXPaymentIntent({ slug, data });
      return res.data;
    } catch (err: any) {
      const error: AxiosError<IErrorResponse> = err;
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOffer.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(listOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.offers = payload?.data;
      state.total = payload?.total;
    });
    builder.addCase(listOffer.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getListUploadOptions.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getListUploadOptions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.uploadOptions = payload?.data;
    });
    builder.addCase(getListUploadOptions.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getUploadedDocmuents.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getUploadedDocmuents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.isRequiredFilesUploaded = payload?.all_required_files_uploaded || false;
      state.currentDocuments = payload?.data;
    });
    builder.addCase(getUploadedDocmuents.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getOffer.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentOffer = payload?.data;
    });
    builder.addCase(getOffer.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getOfferWorkFlow.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getOfferWorkFlow.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.workflow = payload?.data;
    });
    builder.addCase(getOfferWorkFlow.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getOfferVSO.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getOfferVSO.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.vsoData = payload?.data;
    });
    builder.addCase(getOfferVSO.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getOfferPaymentSummary.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getOfferPaymentSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.paymentSummary = payload?.data;
    });
    builder.addCase(getOfferPaymentSummary.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(getLoanResult.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getLoanResult.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.loanResult = payload?.data;
    });
    builder.addCase(getLoanResult.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });

    builder.addCase(createPaymentIntent.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(createPaymentIntent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.paymentIntentData = payload?.data;
    });
    builder.addCase(createPaymentIntent.rejected, (state, action) => {
      state.loading = false;
      let error: string | null = null;
      if (action.payload && typeof action.payload === 'object') {
        if ('errors' in action.payload && action.payload.errors) {
          if (typeof action.payload.errors === 'string') {
            error = action.payload.errors;
          } else if (typeof action.payload.errors === 'object' && 'message' in action.payload.errors) {
            error = (action.payload.errors as { message: string }).message;
          } else {
            error = 'An error occurred during payment processing.';
          }
        } else {
          error = action.error.message || 'An error occurred during payment processing.';
        }
      } else {
        error = action.error.message || 'An error occurred during payment processing.';
      }
      state.errors = error;
    });

    builder.addCase(sendAcceptTC.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(sendAcceptTC.fulfilled, (state) => {
      state.loading = false;
      state.isAcceptedTC = true;
    });
    builder.addCase(sendAcceptTC.rejected, (state, action) => {
      state.loading = false;
      let error: string | null = null;
      if (action.payload && typeof action.payload === 'object') {
        if ('errors' in action.payload && action.payload.errors) {
          if (typeof action.payload.errors === 'string') {
            error = action.payload.errors;
          } else if (typeof action.payload.errors === 'object' && 'message' in action.payload.errors) {
            error = (action.payload.errors as { message: string }).message;
          } else {
            error = 'An error occurred during payment processing.';
          }
        } else {
          error = action.error.message || 'An error occurred during payment processing.';
        }
      } else {
        error = action.error.message || 'An error occurred during payment processing.';
      }
      state.errors = error;
    });

    builder.addCase(sendAcceptOffer.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(sendAcceptOffer.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendAcceptOffer.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createPaymentGateway.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(createPaymentGateway.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPaymentGateway.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(sendFinishUploadCash.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(sendFinishUploadCash.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendFinishUploadCash.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(removeSelectedFile.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(removeSelectedFile.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(removeSelectedFile.rejected, (state, action) => {
      state.loading = false;
      let error: string | null = null;
      if (action.payload) {
        error = action.error.message || 'An error occurred processing.';
      }
      state.errors = error;
    });

    builder.addCase(sendFinanceOption.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });

    builder.addCase(sendFinanceOption.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(sendFinanceOption.rejected, (state, action) => {
      state.loading = false;
      let error: string | null = null;
      if (action.payload) {
        error = action.error.message || 'An error occurred during payment processing.';
      }
      state.errors = error;
    });

    builder.addCase(sendUploadDoc.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });

    builder.addCase(sendUploadDoc.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(sendUploadDoc.rejected, (state, action) => {
      state.loading = false;
      let error: string | null = null;
      if (action.payload) {
        error = action.error.message || 'An error occurred during payment processing.';
      }
      state.errors = error;
    });

    builder.addCase(fetchFPXPaymentIntent.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(fetchFPXPaymentIntent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.fpx = payload?.data;
    });
    builder.addCase(fetchFPXPaymentIntent.rejected, (state, action) => {
      state.loading = false;
      let error: string | null = null;
      if (action.payload && typeof action.payload === 'object') {
        if ('errors' in action.payload && action.payload.errors) {
          if (typeof action.payload.errors === 'string') {
            error = action.payload.errors;
          } else if (typeof action.payload.errors === 'object' && 'message' in action.payload.errors) {
            error = (action.payload.errors as { message: string }).message;
          } else {
            error = 'An error occurred during payment processing.';
          }
        } else {
          error = action.error.message || 'An error occurred during payment processing.';
        }
      } else {
        error = action.error.message || 'An error occurred during payment processing.';
      }
      state.errors = error;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
const {} = slice.actions;
export {};

export default slice.reducer;
