import React from 'react';
import { Trans } from 'react-i18next';

interface ICustomTextWithFontFamily {
  text?: string;
  fontFamily?: string;
  components?: Record<string, any>;
}

const CustomTextWithFontFamily: React.FC<ICustomTextWithFontFamily> = ({ text, fontFamily, components }) =>
  text ? (
    <Trans defaults={text} components={{ ...components, specificFont: <span style={{ fontFamily }} />, br: <br /> }} />
  ) : null;

CustomTextWithFontFamily.defaultProps = {
  text: '',
  fontFamily: 'Poppins',
};

export default CustomTextWithFontFamily;
